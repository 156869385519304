/**
 * Permet de déclencher des événements d'apparition / disparition au clic sur un élément
 * data-trigger = "xxx" L'élément cliquable
 * data-target = "xxx" L'élément à afficher / masquer
 * Au clic sur data-trigger, la classe "on" est ajouté au déclencheur et à la cible
 * 
 * data-trigger = "xxx" L'élément cliquable
 * data-target = "xxx" L'élément à afficher / masquer
 * data-target-class = "is-expanded" >
 *
 * Au clic sur data-trigger, la classe "on" [+ la classe optionnelle précisée] est ajouté au déclencheur et à la cible
 *
 * Exemple :
 * ```html
 * 		<div class="cible" data-target="burger"> ;-) </div>
 * 		<button class="header-burger"
 * 			data-trigger="burger"
 * 			data-target-class="nav-is-expanded"> Hey ! </button>
 * ```
 */
! function () {
    let triggers = document.querySelectorAll("[data-trigger]");
    triggers.forEach(function (trigger) {
        //Au clic sur chaque trigger
        trigger.onclick = function () {
			
            // Classes à appliquer, ex: on,no-scroll,etc
			let triggerClass = ['on'];
			// classe cible
			let specialTargetClass = trigger.dataset.targetClass
			if ( specialTargetClass !== null &&
					specialTargetClass !== undefined )
			{
				// convertion en array
				specialTargetClass = specialTargetClass.split(' ');
				// assembler la liste des classes
				triggerClass = [...triggerClass, ...specialTargetClass];
			}

			// Trigger : modifier les classes
			triggerClass.forEach( theClass => {
				trigger.classList.toggle( theClass )
			});

            // Target : récupèrer les target associées
            let value = trigger.getAttribute('data-trigger');
            let targets = document.querySelectorAll('[data-target="' + value + '"]');
            // toggle les classes chaque target
            targets.forEach((target) => {
				// on lui ajoute les classes définies
				triggerClass.forEach( theClass => {
					target.classList.toggle( theClass );
				});
            })
        }
    });
}();

/***
 * Récupère un cookie
 * key = Le nom du cookie
 */
export function getCookie(key) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

/**
 * Écrit un cookie
 * @param {*} key : Le nom du cookie 
 * @param {*} value : La valeur du cookie
 * @param {*} expiry : La durée du cookie en millisecondes
 */
export function setCookie(key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (expiry));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
}

export function formatParams(params) {
    return "?" + Object
        .keys(params)
        .map(function (key) {
            return key + "=" + encodeURIComponent(params[key])
        })
        .join("&")
}