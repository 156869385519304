import { formatParams } from './utils';

!(function () {
    formsEvents();
})();

/**
 * Modifie le style du formulaire à la soumission (loader)
 * Masque le formulaire lorsque le message est envoyé et affiche un message de confirmation
 */
export default function formsEvents() {
    let cf7Forms = document.querySelectorAll('.wpcf7');

    if (cf7Forms && cf7Forms != null) {
        cf7Forms.forEach((cf7Form) => {
            createLoader(cf7Form);

            let form = cf7Form.querySelector('form');
            let loader = cf7Form.querySelector('.form-loader-wrapper');

            //Affichage du loader à la soumission
            form.addEventListener(
                'submit',
                function (event) {
                    if (loader && loader != null) {
                        loader.classList.add('loading');
                    }
                },
                false
            );

            //Masquage du loader lorsque le formulaire est traité
            cf7Form.addEventListener('wpcf7submit', function () {
                if (loader && loader != null) {
                    loader.classList.remove('loading');
                }
            });

            //Masquage du formulaire lorsque l'envoi est réussi + affichage du message de confirmation
            cf7Form.addEventListener(
                'wpcf7mailsent',
                function (event) {
                    //On masque le formulaire si c'est un formulaire popup
                    let popup = document.querySelector('.popup-overlay');
                    if (
                        popup &&
                        popup != null &&
                        popup.classList.contains('on')
                    ) {
                        popup.classList.remove('on');
                        popup.classList.add('popup-to-remove');
                    }

                    //On créée une popup pour afficher le message de confirmation
                    let path = getAjaxUrl();
                    let parameters = {
                        action: 'confirmationmaker',
                        form_id: event.detail.contactFormId,
                    };

                    let xhr = new XMLHttpRequest();
                    xhr.open('GET', path + formatParams(parameters), true);
                    xhr.send(parameters);
                    xhr.onload = function () {
                        if (xhr.status >= 200 && xhr.status < 300) {
                            let response = JSON.parse(xhr.responseText);
                            let datas = response.data;

                            //Si le formulaire est un popup, on le cache
                            let popup = document.querySelector(
                                '.popup-to-remove'
                            );
                            if (popup && popup != null) {
                                popup.parentNode.removeChild(popup);
                            }

                            //Création de la popup
                            var wrapper = document.createElement('div');
                            wrapper.classList.add('popup-overlay');
                            wrapper.innerHTML = datas.popupContent;
                            wrapper.classList.add('on');

                            var container = document.getElementById('main');
                            document.body.insertBefore(wrapper, container);

                            //Fermeture de la popup
                            let close = wrapper.querySelector('.popup-close');
                            close.addEventListener('click', function () {
                                wrapper.classList.remove('on');
                                wrapper.parentNode.removeChild(wrapper);
                            });

                            //Gestion du tracking
                            CreateTracking(datas.tracking);
                        }
                    };
                },
                false
            );
        });
    }
}

/**
 * Gère la remontée des evénements dans analytics
 * @param {*} tracking : les informations permettant la création de l'événement
 */
function CreateTracking(tracking) {
    gtag('event', tracking.action, {
        event_category: tracking.category,
        event_label: tracking.label,
    });
}

/**
 * Créé un loader
 * @param {*} container : Wrapper du formulaire qui sera rempli par le loader
 */
function createLoader(container) {
    let loaderWrapper = document.createElement('div');
    loaderWrapper.classList.add('form-loader-wrapper');
    container.appendChild(loaderWrapper);

    //Titre
    let loaderTitle = document.createElement('div');
    loaderTitle.classList.add('form-loader-title');
    loaderTitle.innerHTML = 'Le formulaire est en cours de traitement.';

    //Animation
    let loaderAnimation = document.createElement('div');
    loaderAnimation.classList.add('lds-ellipsis');
    for (let i = 0; i < 4; i++) {
        let loaderElement = document.createElement('div');
        loaderAnimation.appendChild(loaderElement);
    }

    //Message
    let loaderTxt = document.createElement('div');
    loaderTxt.classList.add('form-loader-txt');
    loaderTxt.innerHTML = 'Merci de patienter';

    loaderWrapper.appendChild(loaderTitle);
    loaderWrapper.appendChild(loaderAnimation);
    loaderWrapper.appendChild(loaderTxt);
}

function getAjaxUrl() {
    return php_vars.ajaxurl;
}

/**
 * Hack pour débloquer la validation des formulaires...
 */
!(function() {
    let buttons = document.querySelectorAll('.wpcf7-submit');
    buttons.forEach(button => {
        button.classList.remove('wpcf7-submit');
    });
})();