!function () {
	let nav_small = document.querySelector('#js-nav-small');
	if ( nav_small )
	{
		initMenuCliping( nav_small )

	}

}();

/**
 * 	Initialise le cliping du menu au centre du burger button 
 *
 */
function initMenuCliping( nav_small ) {
	const nav__el = nav_small
	let toggle_nav_el = nav__el.querySelector(".js-nav-main-trigger");
	let nav_conatainer = nav__el.querySelector("#nav-main--container");

	if (toggle_nav_el && toggle_nav_el != null && nav_conatainer && nav_conatainer != null) {
		// Rafraichir la position au scroll et resize
		window.addEventListener('scroll', () => setMapCliping(nav_conatainer, toggle_nav_el), false);
		window.addEventListener('resize', () => setMapCliping(nav_conatainer, toggle_nav_el), false);
		setMapCliping(nav_conatainer, toggle_nav_el)
	}
}
/**
 * Affecte le cliping du menu au centre du burger button 
 */
function setMapCliping(nav_small_conatainer, toggle_nav_el) {
	// Obtenir la position du switch
	let toggle_nav__pos = toggle_nav_el.getBoundingClientRect();
	let nav_small__toggle_origine__x = parseInt(toggle_nav__pos.left + (toggle_nav__pos.width / 2))
	let nav_small__toggle_origine__y = parseInt(toggle_nav__pos.top + (toggle_nav__pos.height / 2))
	// Modifier le clip-path de la carte
	nav_small_conatainer.setAttribute("style", `clip-path: circle(1px at ${nav_small__toggle_origine__x}px ${nav_small__toggle_origine__y}px ); -webkit-clip-path: circle(1px at ${nav_small__toggle_origine__x}px ${nav_small__toggle_origine__y}px );`)
}