/**
 * Détecte le user agent et ajout une classe correspondant sur le body de la page
 */
! function () {
    var elt = document.querySelector('body');

    //#region Opera 8.0+
    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    if (isOpera)
        elt.classList.add('opera');
    //#endregion

    //#region Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox)
        elt.classList.add('firefox');
    //#endregion

    //#region Safari 3.0+ "[object HTMLElementConstructor]" 
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || safari.pushNotification);
    if (isSafari)
        elt.classList.add('safari');
    //#endregion

    //#region Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE)
        elt.classList.add('internet-explorer');
    //#endregion

    //#region Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    if (isEdge)
        elt.classList.add('edge');
    //#endregion

    //#region Chrome 1+
    var isChrome = !!window.chrome && !!window.chrome.webstore;
    if (isChrome)
        elt.classList.add('chrome');
    //#endregion

    //#region Blink engine detection
    var isBlink = (isChrome || isOpera) && !!window.CSS;
    if (isBlink)
        elt.classList.add('blink');
    //#endregion
}();

/**
 * Détecte si le javascript est activé et modifie la classe du body
 */
! function () {
    let html = document.querySelectorAll("html");
    for (let h = 0; h < html.length; h++) {
        html[h].classList.remove("no-js");
    }
}();

/**
 * Ajoute la taille du header dans les variables css
 */
! function () {
    let header = document.querySelector('.header-wrapper');
    let headerHeight = parseFloat(getComputedStyle(header, null).height.replace("px", ""));

    document.documentElement.style.setProperty('--header-height', headerHeight + "px");
}();

/**
 * Se base sur le viewport pour vérifier l'affichage
 */
export function isMobile() {
    return window.innerWidth < 768;
}

/**
 * Détermine si un élément est complètement visible ou non
 */
export function isFullInViewport(elem) {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

/**
 * Détermine si un élement est partiellement visible
 * @param { l'élément à tester} elt 
 */
export function isInViewport(elt) {
    return elt.getBoundingClientRect().top < window.innerHeight && elt.getBoundingClientRect().bottom >= 0;
}