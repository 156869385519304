import Rellax from 'rellax';


var rellax = new Rellax('.parallax');


//* parallax des logos des références
var specRellax = new Rellax('.parallax--medium', {
	breakpoints:[ 576, 768, 1201 ],
	// wrapper:'.logos-col',
	center:true
});

