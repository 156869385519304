function incNumber(i, number, inc) {
    if (i <= number) {
        inc.innerHTML = i;
        setTimeout(function() {
            incNumber(i + 1, number, inc);
        }, 10);
    }
}

function counter(i, number, inc) {
    if (i <= number) {
        inc.innerHTML = i;
        setTimeout(function() {
            incNumber(i + 10000, number, inc);
        }, 10);
    }
}
(function() {
    const body = document.querySelector('.home')
    if (body) {
        const increases = document.querySelectorAll('.increase')
        const section = document.querySelector('.blocks--columns.home-services ')
        if (section) {
            const positon = section.getBoundingClientRect().top
            window.addEventListener('scroll', (event) => {
                /*if (window.pageYOffset > positon) {
                    if (increases) {
                        increases.forEach((inc) => {
                            let number = inc.innerHTML
                            const numberDef = inc.id
                            switch (numberDef) {
                                case "1":
                                    //incNumberMax(1000, parseInt(number), inc)
                                    counter(110000, parseInt(number), inc)
                                    break
                                case "2":
                                    incNumber(0, parseInt(number), inc)
                                    break
                                case "3":
                                    break
                                default:
                                    break
                            }
                        })
                    }
                }*/
                event.stopPropagation()
            })
        }
    } else {
        return
    }
})();