import Glide from '@glidejs/glide'

!(function () {
    let sliders = document.querySelectorAll('.glide');

    if (sliders != null) {
        sliders.forEach((slider) => {
            initSlider(slider);
        });
    }
})();

function initSlider(slider) {
    let slides = slider.querySelectorAll('.glide__slide');

    if (!slides || slides == null || slides.length == 0) return;

    let slidesPerView = 3;
    let gap = 10;
    let peek = 10;

    if (window.innerWidth < 970) {
        gap = 0;
        peek = 0;
        slidesPerView = 1;
    } else if (window.innerWidth < 1300) {
        slidesPerView = 2;
    }

    //Slider Actualité
    if (slider.classList.contains('home-actuality-slider')) {
        slidesPerView = 5;
		gap = 10; 
		peek = 0;
        if (window.innerWidth < 768) {
            slidesPerView = 1;
            peek = {
                before: 0,
                after: 50,
            };
           gap = 20; 
        } else if (window.innerWidth < 970) {
            slidesPerView = 2.5;
        } else if (window.innerWidth < 1024) {
            slidesPerView = 3.5;
        }
		else if (window.innerWidth < 1224) {
            slidesPerView = 4;
        }
    }

    if (slider.classList.contains('realization-detail-slider'))
    {
        slidesPerView = 2.4;
		 
        if (window.innerWidth < 768) {
            slidesPerView = 1;
            peek = {
                before: 0,
                after: 50,
            };
           gap = 10; 
        } else if (window.innerWidth < 970) {
            slidesPerView = 1.5;
        }
    }

    if (slider.classList.contains('realization-block-slider'))
    {
        slidesPerView = 4;
		gap = 20; 
		peek = 0;
        if (window.innerWidth < 768) {
            slidesPerView = 1;
            peek = {
                before: 0,
                after: 50,
            };
           gap = 20; 
        } else if (window.innerWidth < 970) {
            slidesPerView = 1.5;
        } else if (window.innerWidth < 1024) {
            slidesPerView = 2.5;
        }
    }

    let options = {
        type: 'carousel',
        autoplay: false,
        animationDuration: 1000,
        rewind: true,
        animationTimingFunc: 'ease-in-out',
        gap: gap,
        perView: slidesPerView,
        peek: peek,
    };

    mountSlider(slider, options);
}

/**
 * Initialise les sliders
 * @param {} sliders
 * @param {*} options
 */
function mountSlider(sliders, options) {
    let slider = new Glide(sliders, options);

    //Si le slider n'a qu'une slide, on le désactive
    slider.on('mount.before', function () {
        if (sliders.querySelectorAll('.glide__slide').length <= 1) {
            slider.update({
                rewind: false,
            });
            slider.disable();
        }
    });

    if (sliders.classList.contains('home-actuality-slider')) {
        slider.on('mount.after', function () {
            setActualitySliderControls(sliders);
        });
        slider.on('run.after', function () {
            setActualitySliderControls(sliders);
        });

		
    }

    slider.mount();
}

function setActualitySliderControls(slider) {
    let controls = document.querySelector('.home-actuality-slider-controls');

	if (controls)
	{

		//Compteur de slides
		let controlsActiveSlide = controls.querySelector(
			'.home-actuality-slider-controls-count-active'
		);
		let controlsTotalSlides = controls.querySelector(
			'.home-actuality-slider-controls-count-total'
		);
	}

    let totalSlides = slider.querySelectorAll('.glide__slide');
    let totalClones = slider.querySelectorAll('.glide__slide--clone');
    let numberSlides = totalSlides.length - totalClones.length;
    let activeSlide = slider.querySelector('.glide__slide--active');

    if (numberSlides) {
        controlsTotalSlides.innerHTML = totalSlides.length - totalClones.length;
    }

    if (activeSlide) {
        controlsActiveSlide.innerHTML = activeSlide.getAttribute(
            'data-slide-index'
        );
    }

    //Barre de progression
    let track = controls.querySelector('.home-actuality-slider-controls-track-indicator');
    let widthTrack = 100 / numberSlides;
    let leftTrack = parseInt(activeSlide.getAttribute(
        'data-slide-index') - 1) * widthTrack;

    track.style.width = widthTrack + "%";
    track.style.left = leftTrack + "%";
}