import Masonry from 'masonry-layout';
import 'imagesloaded';

! function () {
    var wrappers = document.querySelectorAll('[data-layout="masonry"]');

    if (!wrappers || wrappers.length == 0)
        return;

    wrappers.forEach(wrapper => {
        var msnry = new Masonry(wrapper, {
            itemSelector: '.masonry-item',
            columnWidth: '.masonry-item',
            gutter: 50,
            percentPosition: true,
            horizontalOrder: true,
            fitWidth: true
        });

        msnry.layout()
    });
}();