import {
    isInViewport
} from './browser-helper';

document.addEventListener('DOMContentLoaded', function() {
    // animateMenu();
    // fixedPictureHeader();
    // slideEffect();
    // appearEffect();
    // wavyEffect();
    // headerEffect();
});

window.addEventListener('scroll', function() {
    // fixedPictureHeader();
    // parallaxEffect();
    // slideEffect();
    // appearEffect();
    // wavyEffectonScroll();
    // headerEffect();
});

function animateMenu() {
    let indicator = document.querySelector('.nav-desktop-indicator');
    let items = document.querySelectorAll('.nav-main-container-item');

    items.forEach((item) => {
        item.addEventListener('mouseenter', function() {
            let elt = item.querySelector('.nav-main-container-item-label');

            indicator.style.width = elt.offsetWidth + 'px';
            indicator.style.left = item.offsetLeft + elt.offsetLeft + 'px';
        });
    });
}

function fixedPictureHeader() {
    if (window.innerWidth > 1023) {
        return;
    }

    let body = document.querySelector('.body');

    if (!body.classList.contains('fixed-picture')) {
        return;
    }

    let header = document.querySelector('.header-wrapper');
    let headerHeight = header.getBoundingClientRect().height;

    // header.classList.add('transparent');

    let main = document.querySelector('.main');

    let titleBlock = document.querySelector('.title-block');
    let titleViewPort = titleBlock.getBoundingClientRect();
    let visibleTitle = titleViewPort.height + titleViewPort.top;

    main.style.marginTop = headerHeight * -1 + 'px';

    // if (titleViewPort.top <= headerHeight * -1) {
    //     header.classList.remove('transparent');
    // }
}

function parallaxEffect() {
    let scrollY = window.scrollY;
    let elts = document.querySelectorAll('[data-parallax]');

    elts.forEach((elt) => {
        switch (elt.getAttribute('data-parallax')) {
            case 'img':
                elt.style.marginTop = scrollY * 0.6 + 'px';
                break;
            case 'background':
                elt.style.backgroundPosition = 'left ' + scrollY * 0.01 + 'px';
                break;
            case 'div':
                elt.style.marginTop = scrollY * 0.3 + 'px';
                break;
            case 'text':
                elt.style.paddingTop = scrollY * -0.6 + 'px';
                break;
        }
    });
}

function slideEffect() {
    let elts = document.querySelectorAll('[data-effect="slide"]');

    elts.forEach((elt) => {
        if (isInViewport(elt)) {
            elt.classList.add('on');
        }
    });
}

function appearEffect() {
    let elts = document.querySelectorAll('[data-effect="appear"]');

    elts.forEach((elt) => {
        if (isInViewport(elt)) {
            elt.classList.add('on');
        } else {
            elt.classList.remove('on');
        }
    });
}

function wavyEffect() {
    let elts = document.querySelectorAll('[data-effect="wavy"]');

    elts.forEach((elt) => {
        let splitted_text = '';
        let lines = elt.innerHTML.split('<br>');

        lines.forEach((line) => {
            splitted_text += '<span class="letter-wavy-line">';

            let words = line.split(' ');
            words.forEach((word) => {
                splitted_text += '<span class="letter-wavy-word">';

                let letters = word.split('');
                letters.forEach((letter) => {
                    splitted_text +=
                        '<span class="letter-wavy">' + letter + '</span>';
                });

                splitted_text += '</span>';
            });

            splitted_text += '</span>';
        });
        elt.innerHTML = splitted_text;

        wavyEffectonScroll();
    });
}

function wavyEffectonScroll() {
    let elts = document.querySelectorAll('[data-effect="wavy"]');

    elts.forEach((elt) => {
        let elt_letters = elt.querySelectorAll('.letter-wavy');
        if (isInViewport(elt)) {
            for (let i = 0; i < elt_letters.length; i++) {
                setTimeout(function() {
                    elt_letters[i].classList.add('on');
                }, i * 20);
            }

            elt.classList.add('on');
        } else {
            for (let i = 0; i < elt_letters.length; i++) {
                setTimeout(function() {
                    elt_letters[i].classList.remove('on');
                }, i * 20);
            }

            elt.classList.remove('on');
        }
    });
}

function headerEffect() {
    let header = document.querySelector('.header-wrapper');
    let body = document.querySelector('body');


    if (body.classList.contains('transparent-header')) {
        console.log(body.classList);
        header.classList.add('transparent');

        if (window.scrollY >= parseFloat(getComputedStyle(header, null).height.replace("px", ""))) {
            header.classList.remove('transparent');
        }
    }
}

function dropdownMobile()
{
	let dds = document.querySelectorAll('.dropdown-mobile');

	if (!dds || dds.length == 0)
	{
		return;
	}

	dds.forEach(dd => {
		console.log('dd', dd);
		let active_items = dd.querySelectorAll('.selector.list-category');

		active_items.forEach(active_item => {
			active_item.addEventListener('click', function()
			{
				let items = dd.querySelectorAll('.list-category');

				console.log(items);
	
				items.forEach(item => {
					if (!item.classList.contains('active') && !item.classList.contains('selector'))
					{
						console.log(item);
						if (item.classList.contains('show'))
						{
							item.classList.remove('show');
						}
						else
						{
							item.classList.add('show');
						}
					}
				});
			});
		});
	});
}

!function()
{
	dropdownMobile();
}();