function scroll() {
    ScrollReveal().reveal('.reveal-from-bottom', {
        origin: 'bottom',
        distance: '60px',
        duration: 800,
        delay: 100
    });
    ScrollReveal().reveal('.reveal-from-left', {
        origin: 'left',
        distance: '60px',
        duration: 800,
        delay: 100
    });
    ScrollReveal().reveal('.reveal-from-right', {
        origin: 'right',
        distance: '60px',
        duration: 800,
        delay: 100
    });
}
(function() {
    const scrollBottom = document.querySelector('.reveal-from-bottom')
    const scrollLeft = document.querySelector('.reveal-from-left')
    const scrollRight = document.querySelector('.reveal-from-right')
    if (scrollBottom || scrollLeft || scrollRight) {
        scroll()
    }
})()