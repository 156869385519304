import formEvents from "./forms";
import { formatParams } from "./utils";

/**
 * Gère l'ouverture de popup
 */
!(function () {
	let path = getAjaxUrl();
	let triggers = document.querySelectorAll("[data-popup]");

	triggers.forEach((trigger) => {
		trigger.addEventListener("click", function (e) {
			createLoader();
			e.preventDefault();

			let popup_type = trigger.getAttribute("data-popup-type");
			let parameters = {
				type: popup_type,
				action: "popupmaker",
			};

			let xhr = new XMLHttpRequest();

			xhr.open("GET", path + formatParams(parameters), true);
			xhr.send(parameters);

			xhr.onload = function () {
				if (xhr.status >= 200 && xhr.status < 300) {
					let response = JSON.parse(xhr.responseText);
					let datas = response.data;

					//Création de la popup
					var wrapper = document.createElement("div");
					wrapper.classList.add("popup-overlay");
					wrapper.innerHTML = datas.popupContent;
					wrapper.classList.add("on");

					var container = document.getElementById("main");
					document.body.insertBefore(wrapper, container);

					//Fermeture de la popup
					let close = wrapper.querySelector(".popup-close");
					close.addEventListener("click", function () {
						wrapper.classList.remove("on");
						wrapper.parentNode.removeChild(wrapper);
					});

					//Suppression du loader
					let Loader = document.querySelector(".popup-loader");
					Loader.remove();

					//On modifie l'action du formulaire cf7
					let form = wrapper.querySelector(".wpcf7-form");
					if (form && form != null) {
						var urL = form.getAttribute("action").split("#");
						form.setAttribute("action", "#" + urL[1]);
						// wpcf7.initForm(form);
						wpcf7.init(form);
						formEvents();
					}

					//On appelle le callback si il y en a
					if (datas.callback) {
						let params = datas.callback.params;
						eval(datas.callback.name + "(params)");
					}

					setHiddenField("page_url", window.location.href);
				} else {
					console.log("The request failed!");
				}
			};
		});
	});

	return false;
})();

function setHiddenField(fieldName, value) {
	var fields = document.getElementsByName(fieldName);
	if (fields.length > 0) {
		fields[0].setAttribute("value", value);
	}
}

function getAjaxUrl() {
	return php_vars.ajaxurl;
}

function createLoader() {
	let container = document.querySelector(".body");
	let loaderWrapper = document.createElement("div");
	loaderWrapper.classList.add("form-loader-wrapper");
	loaderWrapper.classList.add("popup-loader");
	container.appendChild(loaderWrapper);

	//Titre
	let loaderTitle = document.createElement("div");
	loaderTitle.classList.add("form-loader-title");
	loaderTitle.innerHTML = "Traitement en cours.";

	//Animation
	let loaderAnimation = document.createElement("div");
	loaderAnimation.classList.add("lds-ellipsis");
	for (let i = 0; i < 4; i++) {
		let loaderElement = document.createElement("div");
		loaderAnimation.appendChild(loaderElement);
	}

	//Message
	let loaderTxt = document.createElement("div");
	loaderTxt.classList.add("form-loader-txt");
	loaderTxt.innerHTML = "Merci de patienter";

	loaderWrapper.appendChild(loaderTitle);
	loaderWrapper.appendChild(loaderAnimation);
	loaderWrapper.appendChild(loaderTxt);

	loaderWrapper.classList.add("loading");
	loaderWrapper.style.position = "fixed";
	loaderWrapper.style.zIndex = "500";
}
