import '../css/style.scss';
import './compatibily';
import './browser-helper';
import './utils';
import './layout';
import './animations';
import './sliders';
import './popup-maker';
import './forms';
import './masonry';
import './navs';
import './increase';
import './scrollReveal';
import './rellaxParallax';
