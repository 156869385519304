//#region 		header	

	// Écoute du scroll + resize

	/**
	 * Passer le menu de navigation en fixe lors du scroll
	 * Ajoute la classe : .is-fixed
	 *
	 */
	function fixeNavOnScroll(elem) {
		if (document.querySelectorAll(elem).length > 0) {
			let navFixe = document.querySelector(elem);
			let navFixe_position = navFixe.getBoundingClientRect();
			let navFixe_position_top = navFixe_position.top;
			// Rafraichir la position au scroll et resize
			function positionRefresh(e) {
				// Position du scroll
				var scroll =
					(document.documentElement &&
						document.documentElement.scrollTop) ||
					document.body.scrollTop;

				if (scroll >= navFixe_position_top && scroll > 0) {
					navFixe.classList.add('is-fixed');
				} else {
					if (navFixe.classList.contains('is-fixed')) {
						navFixe.classList.remove('is-fixed');
					}
				}
			}
			window.addEventListener('DOMContentLoaded', positionRefresh, false);
			window.addEventListener('scroll', positionRefresh, false);
			window.addEventListener('resize', positionRefresh, false);
		}
	}
	fixeNavOnScroll('.js-header');

//#endregion